import {App} from "antd";
import {AppointmentStock} from "../../../entities/appointment-stock";
import {instance} from "../../../services/axios-config";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {useState} from "react";

const saveAppointment = async (appointment: AppointmentStock) => {
  await instance.post('/v1/appointment_stock/add', appointment);
}

export function DoSaveStockAppointment() {
  const {notification} = App.useApp();
  const [concludingAppointment, setConcludingAppointment] = useState(false);
  const clear = () => {
    setConcludingAppointment(false);
  }

  const {mutateAsync, isLoading} = useMutation(saveAppointment, {
      onMutate: () => {
        setConcludingAppointment(true);
      },
      onError: (error: AxiosError) => {
        setConcludingAppointment(false);
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );

  return {mutateAsync, isLoading, concludingAppointment, clear};
}