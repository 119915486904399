import {Table} from "antd";
import './sample-preview-list.css'
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate} from "../../../services/date-format";
import ReceivableType from "../../../entities/receivable-type";
import React from "react";
import SampleWallet from "../../../entities/sample-wallet";
import PropTypes from "prop-types";

export const SampleWalletPreviewList = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const receivableTypes: ReceivableType[] = location.state.receivableTypes;

  const columns = [
    {
      title: 'Tipo Recebível',
      dataIndex: 'receivableType',
    },
    {
      title: 'Fundo',
      dataIndex: 'fund',
    },
    {
      title: 'Emitente',
      dataIndex: 'emitter',
    },
    {
      title: 'Papel',
      dataIndex: 'documentPaper',
    },
    {
      title: 'Código',
      dataIndex: 'documentCode',
    },
    {
      title: 'Proposta',
      dataIndex: 'proposal',
    },
    {
      title: 'Data aplicação',
      dataIndex: 'applicationDate',
    },
  ];

  const tableData = () => {
    return props.data.map((it: SampleWallet) => ({
      key: it.id,
      fund: it.fund,
      emitter: it.emitter,
      documentPaper: it.documentPaper,
      documentCode: it.documentCode,
      proposal: it.proposal,
      applicationDate: formatDate(it.applicationDate),
      receivableType: receivableTypes.find(x => x.id === it.receivableTypeId)?.code,
    }));
  }

  function onItemClick(record: any) {
    const samples = props.data?.items ?? [];
    const s = samples.find((it: SampleWallet) => it.id === record.key)!;

    navigate(
      `/archive-wallet-page/item/${s.id}`,
      {
        state: {
          sample: s,
          receivableType: receivableTypes?.find(it => it.id === s.receivableTypeId),
          receivableTypes: receivableTypes,
        }
      }
    );
  }

  return (
    <Table
      className='table'
      loading={props.isLoading}
      columns={columns}
      dataSource={tableData()}
      pagination={false}
      onRow={(record, _) => {
        return {
          onClick: (_) => onItemClick(record),
        };
      }}
    />
  );
}

SampleWalletPreviewList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
}