import {Flex, Menu, Tooltip} from "antd";
import Sider from "antd/es/layout/Sider";
import React, {useContext} from "react";
import RouteOptions from "../../routes/routes";
import {useNavigate} from "react-router-dom";
import {CaretLeftOutlined} from "@ant-design/icons";
import {AuthContext} from "../../services/auth-context";
import {UserContext} from "../../services/user-context";
import {ItemType} from "antd/es/menu/interface";
import "./navbar.css"
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";

export default function Navbar() {
  const {setToken} = useContext(AuthContext);
  const {setUser, user} = useContext(UserContext);
  const wscContext = useContext(WorkingSystemConfigContext);

  const navigate = useNavigate();
  const items = RouteOptions
    .filter(it => it.key != null)
    .filter(it => it.allow.includes(user.role))
    .map(it => {
      return {
        'key': it.key,
        'icon': it.icon,
        'label': it.label,
        'path': it.path,
      }
    });

  return (
    <Sider
      theme="dark"
      breakpoint="md"
      collapsed={true}
      style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
    >
      <Flex className='quarter-label' justify="center" align='center' vertical>
        <Tooltip title={wscContext.value.managerLabel} placement='right'>
          <label>{`${wscContext.value.quarter}/${wscContext.value.quarterYear}`}</label>
        </Tooltip>
      </Flex>
      <div style={{minHeight: 'calc(100% - 50px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Menu theme="dark"
            mode="inline"
            defaultSelectedKeys={[RouteOptions.find((it) => {
              let slice = window.location.pathname.split('/')
              return '/' + slice[1] === it.path
            })?.key ?? '1']}
            items={items as ItemType[]}
            onClick={(info) => {
              let route = RouteOptions.find((it) => it.key === info.key)
              navigate(route!.path, {replace: false})
            }}
      />
      <Menu
        mode="inline"
        theme="dark"
        items={[
          {
            'key': 'exit',
            'icon': React.createElement(CaretLeftOutlined),
            'label': 'Sair',
            onClick: async () => {
              setToken('');
              setUser('');

              navigate('/login', {
                replace: true,
                state: {
                  commingFromRedirect: true
                }
              });
            }
          },
        ]}
      >
      </Menu>
      </div>
    </Sider>
  )
}