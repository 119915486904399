import {useLocation, useNavigate} from "react-router-dom";
import {Breadcrumb, Button, Col, Divider, Result, Row, UploadFile} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {ArchiveFormComponent} from "./components/archive-form-component";
import {DescriptionComponent} from "./components/description-component";
import {parseCurrrency} from "../../services/number-fomat";
import {AppointmentStock} from "../../entities/appointment-stock";
import useForceUpdate from "antd/es/_util/hooks/useForceUpdate";
import dayjs from "dayjs";
import {DoSaveStockAppointment} from "./hooks/do-save-stock-appointment";
import CustomResultComponent from "../../components/custom-result-Component";
import {BaseContent} from "../../components/base-content";
import {HomeOutlined, LoadingOutlined} from "@ant-design/icons";
import {DocumentType} from "../../entities/document-type";
import {ResumeModal} from "./components/resume-modal";
import {SampleStockPreviewList} from "./components/sample-stock-preview-list";
import {DoLoadStockAppointment} from "./hooks/do-load-stock-appointment";
import {useSampleStockFilterService} from "../archive-stock/service/use-sample-stock-filter-service";
import {DoLoadNextSampleStockQuery} from "./hooks/do-load-next-sample-stock";
import {WorkingDocumentTypeContext} from "../../services/working-document-type-context-provider";
import {PdfComponent} from "./components/pdf-component";
import {DynamicField} from "../../entities/dynamic-field";
import {DoLoadDivergenceTypeList} from "../../hooks/do-load-divergence-type-list";
import {DoLoadDocumentTypeList} from "../../hooks/do-load-document-type-list";
import "./archive-appointment-stock-page.css"
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";
import {DoLoadPreviousSampleStock} from "./hooks/do-load-previous-sample-stock";
import {useForm} from "antd/es/form/Form";
import ReceivableType from "../../entities/receivable-type";

export function ArchiveAppointmentStockPage() {
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const navigate = useNavigate();
  const sample = location.state?.sample;
  const receivableType = location.state?.receivableType;
  const wdtContext = useContext(WorkingDocumentTypeContext);
  const wscContext = useContext(WorkingSystemConfigContext);

  const [appointment, setAppointment] = useState(new AppointmentStock());
  const [isBlock, setIsBlock] = useState(false);
  const [modalResume, setModalResume] = useState(false);
  const [preview, setPreview] = useState<any>();
  const [file, setFile] = useState<any>();
  const [formInstance] = useForm();

  const doSaveAppointment = DoSaveStockAppointment();
  const documentTypes = DoLoadDocumentTypeList(receivableType.id);
  const divergenceTypes = DoLoadDivergenceTypeList(receivableType.id);
  const appointmentQuery = DoLoadStockAppointment(sample.id, wdtContext.value?.id);
  const filterService = useSampleStockFilterService();
  const doLoadPreviousSample = DoLoadPreviousSampleStock();
  const receivableTypes: ReceivableType[] = location.state.receivableTypes;
  const sampleQuery = DoLoadNextSampleStockQuery({
    filters: filterService.value,
    id: sample.id,
    quantity: 5,
    quarter: wscContext.value.quarter,
    manager: wscContext.value.manager,
    quarterYear: wscContext.value.quarterYear,
  });

  const onSaveInput = (value: any) => {
    setAppointment(prevState => {
      // RIGHT FORM
      if (value.get('assignorId') !== undefined && sample.assignorId !== value.get('assignorId'))
        prevState.assignorId = value.get('assignorId');
      if (value.get('assignorName') !== undefined && sample.assignorName !== value.get('assignorName'))
        prevState.assignorName = value.get('assignorName');
      if (value.get('draweeId') !== undefined && sample.draweeId !== value.get('draweeId'))
        prevState.draweeId = value.get('draweeId');
      if (value.get('draweeName') !== undefined && sample.draweeName !== value.get('draweeName'))
        prevState.draweeName = value.get('draweeName');
      if (value.get('documentNumber') !== undefined && sample.documentNumber !== value.get('documentNumber'))
        prevState.documentNumber = value.get('documentNumber');
      if (value.get('yourNumber') !== undefined && sample.yourNumber !== value.get('yourNumber'))
        prevState.yourNumber = value.get('yourNumber');

      if (value.get('dueDate') !== undefined && value.get('dueDate') !== null) {
        const dueDate = value.get('dueDate') as dayjs.Dayjs;
        if (dueDate.diff(dayjs(sample.dueDate)) !== 0) {
          prevState.dueDate = dueDate.toDate();
        }
      }

      const nv = parseCurrrency(value.get('nominalValue')).toFixed(2)
      if (value.get('nominalValue') !== undefined && Number(sample.nominalValue) !== Number(nv))
        prevState.nominalValue = nv;

      // LEFT FORM
      if (value.get('comment') !== undefined)
        prevState.comment = value.get('comment');
      if (value.get('divergenceType') !== undefined)
        prevState.divergenceTypeIds = value.get('divergenceType');
      if (value.get('totalValue') !== undefined) {
        prevState.totalValue = value.get('totalValue') !== undefined
          ? parseCurrrency(value.get('totalValue')).toFixed(2)
          : undefined;
      }
      if (value.has('custom')) {
        const customFields = value.get('custom');
        if (customFields) {
          prevState.customFields = Object
            .entries(customFields)
            .map<DynamicField>(([k, v]) => new DynamicField(k, v))
        }
      }

      return AppointmentStock.CopyFrom(prevState);
    });
    forceUpdate();
  };

  const clearAppointment = (items: string[]) => {
    setAppointment(prevState => {
      items.forEach(it => {
        switch (it) {
          case 'assignorId':
            prevState.assignorId = undefined;
            break;
          case 'assignorName':
            prevState.assignorName = undefined;
            break;
          case 'draweeId':
            prevState.draweeId = undefined;
            break;
          case 'draweeName':
            prevState.draweeName = undefined;
            break;
          case 'documentNumber':
            prevState.documentNumber = undefined;
            break;
          case 'yourNumber':
            prevState.yourNumber = undefined;
            break;
          case 'dueDate':
            prevState.dueDate = undefined;
            break;
          case 'nominalValue':
            prevState.nominalValue = undefined;
            break;
          case 'comment':
            prevState.comment = undefined;
            break;
          case 'divergenceType':
            prevState.divergenceTypeIds = undefined;
            break;
          case 'totalValue':
            prevState.totalValue = undefined;
            break;
          case 'custom':
            prevState.customFields = undefined;
            break;
        }
      });

      return AppointmentStock.CopyFrom(prevState);
    });
    forceUpdate();
  };

  const onFinishForm = (value: any) => {
    onSaveForm(value);
    setModalResume(true);
  };

  const onSaveForm = (value: any) => {
    appointment.sampleId = sample.id;
    appointment.comment = value.get('comment');
    appointment.divergenceTypeIds = value.get('divergenceType');
    appointment.totalValue = value.get('totalValue') !== undefined
      ? parseCurrrency(value.get('totalValue')).toFixed(2)
      : undefined;
    appointment.documentTypeId = wdtContext.value?.id;
    appointment.situation = value.get('situation');

    const customFields = value.get('custom');
    if (customFields) {
      appointment.customFields = Object
        .entries(customFields)
        .map<DynamicField>(([k, v]) => new DynamicField(k, v))
    }
  }

  const onSelectDocumentType = (dt: DocumentType) => {
    wdtContext.setValue(dt);
    setAppointment(pa => {
      pa.comment = undefined;
      pa.divergenceTypeIds = undefined;
      pa.totalValue = undefined;
      pa.customFields = undefined;

      return AppointmentStock.CopyFrom(pa);
    });
  }

  useEffect(() => {
    if (documentTypes.data) {
      const r = documentTypes.data!.findIndex(x => x.id === wdtContext.value?.id);
      if (r < 0) {
        wdtContext.setValue(documentTypes.data![0]);
      }
    }

    appointmentQuery.refetch();
  }, [wdtContext.value, sample]);

  useEffect(() => {
    if (documentTypes.data) {
      const r = documentTypes.data!.findIndex(x => x.id === wdtContext.value?.id);
      if (r < 0) {
        wdtContext.setValue(documentTypes.data![0]);
      }
    }

    if (
      sample !== undefined &&
      receivableType !== undefined
    ) return;

    navigate('/archive-stock-page', {
      replace: true
    });
  });

  useEffect(() => {
    if (appointmentQuery.data != null) {
      appointmentQuery.data.documentTypeId = wdtContext.value?.id;

      setAppointment(appointmentQuery.data);
      setIsBlock(false);
    } else {
      setAppointment(_ => {
        const a = new AppointmentStock();
        a.documentTypeId = wdtContext.value?.id;
        return a;
      });
      setIsBlock(false);
    }
  }, [appointmentQuery.data]);

  const onClosePreviewTap = () => {
    setPreview(null);
  }

  const onPreview = (file: UploadFile) => {
    setPreview(file.originFileObj ? URL.createObjectURL(file.originFileObj) : file.url);
    setFile(file);
  };

  if (documentTypes.isLoading || divergenceTypes.isLoading) {
    return (
      <BaseContent>
        <Result icon={<LoadingOutlined/>}/>
      </BaseContent>
    );
  }

  const doRedirectToNext = async () => {
    const s = sampleQuery.data?.items[0];
    if (s == null) return;

    navigate(
      `/archive-stock-page/item/${s.id}`,
      {
        state: {
          sample: s,
          receivableType: receivableTypes?.find(it => it.id === s.receivableTypeId),
          receivableTypes: receivableTypes,
        },
      }
    );

    setAppointment(new AppointmentStock());
    formInstance.resetFields();
    doSaveAppointment.clear();
  }

  const doRedirectToPrevious = async () => {
    const result = await doLoadPreviousSample.mutateAsync({
      filters: filterService.value,
      id: sample.id,
      quarter: wscContext.value.quarter,
      manager: wscContext.value.manager,
      quarterYear: wscContext.value.quarterYear,
    });

    if (result) {
      navigate('/archive-stock-page', {
        replace: true,
        state: {
          sampleRedirect: result.items[0],
        }
      });
      return;
    }

    navigate('/archive-stock-page', {replace: true});
  }

  return (
    <BaseContent>
      {
        doSaveAppointment.concludingAppointment
          ? (
            <CustomResultComponent
              isLoading={doSaveAppointment.isLoading}
              doRedirect={doRedirectToNext}
            />
          )
          : (
            <div>
              <Breadcrumb
                items={[
                  {title: <HomeOutlined/>, href: '/'},
                  {title: 'Auditoria de estoque', href: '/archive-stock-page'},
                  {title: sample.id}
                ]}
              />
              <div style={{marginBlock: 10}}>
                <Button type="primary" style={{marginRight: 5}} onClick={doRedirectToPrevious}>Anterior</Button>
                <Button type="primary" style={{marginLeft: 5}} onClick={doRedirectToNext}>Próximo</Button>
              </div>
              <Divider/>
              <Row gutter={[16, 16]}>
                <Col span="12">
                  <DescriptionComponent
                    isBlock={isBlock}
                    appointment={appointment}
                    onSaveInput={onSaveInput}
                    clearAppointment={clearAppointment}
                  />
                  {
                    preview
                      ? <>
                        <br/>
                        <ArchiveFormComponent
                          isBlock={isBlock}
                          divergenceTypes={divergenceTypes.data ?? []}
                          documentTypes={documentTypes.data ?? []}
                          onSelectDocumentType={(v) => onSelectDocumentType(v)}
                          documentType={wdtContext.value}
                          appointment={appointment}
                          onFinishSubmit={onFinishForm}
                          onPreview={onPreview}
                          whenClose={onClosePreviewTap}
                          onSaveInput={onSaveInput}
                          formInstance={formInstance}
                        />
                      </>
                      : <></>
                  }
                </Col>
                <Col span='12'>
                  {
                    preview
                      ? <>
                        <Button type='text' onClick={onClosePreviewTap}>Fechar</Button>
                        <div
                          className='fixed-preview'
                        >
                          {
                            file.isPdf
                              ? <PdfComponent pdfPath={preview ?? ''}/>
                              : <img alt="" src={preview} style={{width: '100%', height: '80vh', objectFit: 'contain'}}/>
                          }
                        </div>
                      </>
                      : <>
                        <ArchiveFormComponent
                          isBlock={isBlock}
                          divergenceTypes={divergenceTypes.data ?? []}
                          documentTypes={documentTypes.data ?? []}
                          onSelectDocumentType={(v) => onSelectDocumentType(v)}
                          documentType={wdtContext.value}
                          appointment={appointment}
                          onFinishSubmit={onFinishForm}
                          onPreview={onPreview}
                          whenClose={onClosePreviewTap}
                          onSaveInput={onSaveInput}
                          formInstance={formInstance}
                        />
                      </>
                  }
                </Col>
              </Row>
              <br/>
              <SampleStockPreviewList
                isLoading={sampleQuery.isLoading || sampleQuery.isRefetching}
                data={sampleQuery.data?.items ?? []}
              />
            </div>
          )
      }
      <ResumeModal
        appointment={appointment}
        isVisible={modalResume}
        divergenceTypes={divergenceTypes.data ?? []}
        onConfirm={async () => {
          await doSaveAppointment.mutateAsync(appointment);
          setModalResume(false);
        }}
        onCancel={() => setModalResume(false)}
      />
    </BaseContent>
  )
}