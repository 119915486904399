import React, {ReactElement, useEffect, useState} from 'react';
import {Button, Flex, Form, Input, Radio, Select, UploadFile} from "antd";
import {InboxOutlined} from '@ant-design/icons';
import {Content} from "antd/es/layout/layout";
import Upload, {UploadChangeParam} from "antd/es/upload";
import {UploadListItem} from "../../../components/upload-list-item";
import {accentFold} from "../../../services/string";
import TextArea from "antd/es/input/TextArea";
import PropTypes from "prop-types";
import {DivergenceType} from "../../../entities/divergence-type";
import {DoLoadDocuments} from "../hooks/do-load-documents";
import {useLocation} from "react-router-dom";
import {DoSaveDocument} from "../hooks/do-save-document";
import {DoDeleteDocument} from "../hooks/do-delete-document";
import {DocumentType} from "../../../entities/document-type";
import {DoLoadDynamicInputFields, DynamicTextField} from "../../../hooks/do-load-dynamic-input-fields";
import {AppointmentSituation} from "../../../entities/appointment-situation";

export const ArchiveFormComponent = (props: any) => {
  const location = useLocation();
  const sample = location.state?.sample;
  const receivableType = location.state?.receivableType;

  const [files, setFiles] = useState<any>([]);
  const formInstance = props.formInstance;
  const documentsQuery = DoLoadDocuments(sample.id, props.documentType?.id);
  const doSaveDocument = DoSaveDocument();
  const doDeleteDocument = DoDeleteDocument();
  const doLoadDynamicInputFields = DoLoadDynamicInputFields(receivableType.id, props.documentType?.id);
  const [dynamicFields, setDynamicFields] = useState<DynamicTextField[]>([]);


  const uploadImage = async (options: any) => {
    try {
      await doSaveDocument.mutateAsync({
        file: options.file,
        sampleId: sample.id,
        documentTypeId: props.documentType?.id,
      });
      await documentsQuery.refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = async (_: UploadChangeParam) => {
    props.whenClose();
    await documentsQuery.refetch();
  };

  const onDelete = async (file: any) => {
    props.whenClose();
    await doDeleteDocument.mutateAsync(file.uid);
    await documentsQuery.refetch();
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => {
    return accentFold((option?.label ?? '').toLowerCase()).includes(accentFold(input.toLowerCase()))
  }

  const onFinishForm = (type: string) => {
    const map = new Map(Object.entries(formInstance.getFieldsValue()));

    switch (type) {
      case 'requestDoc':
        map.set('situation', AppointmentSituation.RequestDocument);
        break;
      default:
        map.set('situation', AppointmentSituation.Ok);
    }

    props.onFinishSubmit(map);
  }

  useEffect(() => {
    formInstance.setFieldValue('comment', props.appointment?.comment);
    formInstance.setFieldValue('divergenceType', props.appointment?.divergenceTypeIds);

    for (const customField of props.appointment.customFields ?? []) {
      formInstance.setFieldValue(['custom', `${customField.textLabel}`], customField.textValue);
    }
  }, [props.appointment]);

  useEffect(() => {
    setFiles(documentsQuery.data);
  }, [documentsQuery.data]);

  useEffect(() => {
    doLoadDynamicInputFields.refetch();
  }, [props.documentType]);

  useEffect(() => {
    if (doLoadDynamicInputFields.data != null) setDynamicFields(doLoadDynamicInputFields.data)
  }, [doLoadDynamicInputFields]);

  const onChangeDivergenceType = (x: any) => {
    const m = new Map();

    m.set('divergenceType', x);
    props.onSaveInput(m);
  }

  const onChangeComment = ({target}: any) => {
    const m = new Map();

    m.set('comment', target.value);
    props.onSaveInput(m);
  }

  const onChangeCustomField = ({target}: any, name: any) => {
    const m = new Map();

    m.set('custom', {[name]: target.value});
    props.onSaveInput(m);
  }

  return (
    <Content>
      {
        <div>
          <Radio.Group
            key={`ReceivableTypeItemModalSelector-RadioGroup`}
            style={{width: '100%'}}
            buttonStyle='solid'
            optionType='button'
            onChange={({target}) => {
              props.whenClose();
              props.onSelectDocumentType(props.documentTypes[target.value]);
            }}
            value={props.documentTypes.findIndex((x: any) => x.id === props.documentType?.id)}>
            {props.documentTypes?.map((it: DocumentType, index: number) => {
              return <Radio.Button
                key={`ReceivableTypeItemModalSelector-RadioButton-${index}`}
                value={index}
              >{it.code}</Radio.Button>
            })}
          </Radio.Group>
          <br/>
          <br/>
          <Form
            disabled={props.isBlock}
            onFinish={(_) => onFinishForm('submit')}
            form={formInstance}
            layout='vertical'
          >
            <Form.Item
              labelCol={{span: 0}}
              wrapperCol={{span: 24}}
            >
              <Upload.Dragger
                customRequest={uploadImage}
                multiple={true}
                fileList={files}
                onChange={onChange}
                itemRender={(_: ReactElement, file: UploadFile, __: object[], ___: any) =>
                  <UploadListItem
                    title={file.name}
                    onDelete={() => onDelete(file)}
                    onPreview={() => props.onPreview(file)}/>
                }
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Clique ou arraste para essa área para fazer upload.</p>
                <p className="ant-upload-hint">Suporte para upload único ou em massa.</p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item
              label='Tipo de divergência'
              name='divergenceType'
            >
              <Select
                mode="multiple"
                placeholder='Selecione o tipo de divergência'
                showSearch
                popupMatchSelectWidth={false}
                style={{width: '100%'}}
                filterOption={filterOption}
                onChange={onChangeDivergenceType}
                options={
                  props.divergenceTypes
                    ?.sort((a: DivergenceType, b: DivergenceType) => a.code.localeCompare(b.code))
                    ?.filter((it: DivergenceType) => {
                      return it.documentTypeIds?.includes(props.documentType?.id);
                    })
                    ?.map((it: DivergenceType) => (
                      {
                        value: it.id,
                        label: `${it.code} ${it.label}`,
                      }
                    ))}
              />
            </Form.Item>
            {
              (dynamicFields)
                .map((it, i) =>
                  <Form.Item
                    name={['custom', `${it.textLabel}`]}
                    key={`${it.textLabel}-${i}`}
                    label={it.textLabel}
                  >
                    <Input
                      key={`in-${it.textLabel}-${i}`}
                      onChange={(e) => onChangeCustomField(e, `${it.textLabel}`)}
                    />
                  </Form.Item>
                )
            }
            <Form.Item
              label='Observação'
              name='comment'
            >
              <TextArea autoSize={{minRows: 2, maxRows: 7}} onChange={onChangeComment}/>
            </Form.Item>
            <Form.Item>
              <Flex gap={300} align='flex-start' justify='center'>
                <Button
                  type="primary"
                  htmlType="submit"
                >Salvar</Button>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => onFinishForm('requestDoc')}
                >Solicitar Documento</Button>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      }
    </Content>
  )
}

ArchiveFormComponent.propTypes = {
  onFinishSubmit: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  divergenceTypes: PropTypes.array.isRequired,
  documentTypes: PropTypes.array.isRequired,
  documentType: PropTypes.object,
  isBlock: PropTypes.bool.isRequired,
  onSelectDocumentType: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  whenClose: PropTypes.func.isRequired,
  onSaveInput: PropTypes.func.isRequired,
  formInstance: PropTypes.object.isRequired,
}